$icomoon-font-path: "../../fonts";
$font-path: '../fonts';
$font-name: 'FrutigerNeue';
@font-face {
    font-family: #{$font-name};
    src:  url('#{$font-path}/FrutigerNeueLTCom-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: #{$font-name};
    src:  url('#{$font-path}/FrutigerNeueLTCom-Italic.ttf');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}
@font-face {
    font-family: #{$font-name};
    src:  url('#{$font-path}/FrutigerNeueLTCom-Black.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: #{$font-name};
    src:  url('#{$font-path}/FrutigerNeueLTCom-Thin.ttf');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}
@import "icomoon/style";

body {
    font-size: 1em;
    background: #000;
    color: #fff;
    overflow-x: hidden;
    cursor: none;
    font-family: #{$font-name}, sans-serif;
    font-weight: 200;
}

main.backendmode, body[id^='ext-'] {
    cursor: default;
}

.will-transform {
    will-change: transform, opacity;
}

.cursor {
    @apply fixed left-0 right-0 pointer-events-none;
    $cursor: &;
    z-index: 999;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    transition: opacity .3s linear, transform .3s $ease-out-cubic;
    mix-blend-mode: difference;
    &__cross {
        @apply absolute left-1/2 top-1/2;
        transition: all .3s $ease-out-cubic;
        div {
            @apply absolute left-1/2 top-1/2;
            width: 16px;
            height: 2px;
            background: #fff;
            transform: translate(-50%, -50%);
            transition: transform .3s $ease-out-cubic, width .3s $ease-out-back, height .3s $ease-out-back, color .3s linear, opacity .3s linear;
        }
        div:nth-child(2) {
            transform: translate(-50%, -50%) rotate(-90deg);
        }
    }
    &__audio {
        @apply absolute left-1/2 top-1/2;
        transition: all .3s $ease-out-cubic;
        opacity: 0;
        &:before {
            @apply absolute -translate-x-1/2 -translate-y-1/2 rounded-full;
            transition: all .6s $ease-out-cubic;
            box-shadow: inset 0 0 0 48px #fff;
            content: '';
            width: 48px;
            height: 48px;
        }
        div {
            @apply absolute -translate-x-1/2 -translate-y-1/2;
            i {
                @apply inline-block;
                transform: translate(1px, 0);
                font-size: 24px;
            }
        }
    }
    &--hover {
        #{$cursor}__cross {
            div {
                width: 26px;
                height: 4px;
                transform: translate(-50%, -50%) rotate(-180deg);
                &:nth-child(2) {
                    transform: translate(-50%, -50%) rotate(-270deg);
                }
            }
        }
    }
    &--close {
        #{$cursor}__cross {
            transform: rotate(45deg);
            div {
                width: 26px;
                height: 4px;
            }
        }
    }
    &--invert {
        mix-blend-mode: difference;
    }
    &--hide {
        opacity: 0;
    }
    &--transparent {
        opacity: .3;
    }
    &--audio {
        #{$cursor}__cross {
            @apply rotate-90;
            opacity: 0;
        }
        #{$cursor}__audio {
            transition: all .6s $ease-out-cubic;
            &:before {
                box-shadow: inset 0 0 0 2px #fff;
            }
        }
        &#{$cursor}--audio--on {
            #{$cursor}__audio--on {
                opacity: 1;
            }
        }
        &#{$cursor}--audio--off {
            #{$cursor}__audio--off {
                opacity: 1;
            }
        }
    }
}

.controls {
    @apply absolute right-2 bottom-2 md:right-10 md:bottom-10 bg-gradient-to-b from-neutral-900 to-neutral-900 via-neutral-700 text-white shadow-md rounded-3xl md:text-2xl transition-all md:opacity-50 md:hover:opacity-100 border-neutral-700;
    z-index: 100;
    border-width: 1px;
    .flex {
        gap: 1px;
    }
    &__button {
        &:first-child {
            @apply rounded-l-3xl;
        }
        &:last-child {
            @apply rounded-r-3xl;
        }
        @apply bg-black md:hover:bg-neutral-700 px-4 py-2 transition-all;
        &.fullscreen i {
            @apply inline-block transition-all scale-75;
        }
    }
}

@media (max-width: 768px) {
    .cursor {
        display: none;
    }
    body {
        cursor: default;
    }
}

// custom heights
.h-screen-3\/2 {
    height: 150vh;
}
@media (max-width: 767px) {

}

section {
    //overflow-x: hidden;
}

#content {
    position: relative;
}

.fade-in {
    @apply transform-gpu duration-300 ease-out translate-y-5 opacity-0 view:translate-y-0 view:opacity-100
}
.group-observe {
    .group-view\:fade-in {
        @apply transform-gpu duration-300 ease-out translate-y-5 opacity-0;
    }
    &.view {
        .group-view\:fade-in {
            @apply translate-y-0 opacity-100;
        }
    }
}

.fade-in-hidden {
    @apply transform-gpu duration-300 ease-out translate-y-5 opacity-0 view:translate-y-0 view:opacity-100
}

a {
    cursor: none;
    &.underline {
        @apply inline-block relative;
        text-decoration: none;
        &:before {
            content: '';
            position: absolute;
            transition: all .3s $ease-out-cubic;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: #fff;
        }
        &.active {
            &:before {
                transition: all .7s $ease-out-cubic;
                width: 100%;
            }
        }
        @media (max-width: 768px) {
            &:before {
                width: 100%;
            }
        }
        @media (min-width: 768px) {
            &:hover {
                &:before {
                    transition: all .7s $ease-out-cubic;
                    width: 100%;
                }
            }
        }
    }
}

a.button {
    &.button--ghost {
        @apply inline-block px-5 py-3 transition-all duration-300 md:hover:font-bold;
    }
    &.button--arrow {
        @apply relative inline-block pl-6 md:pl-0 transition-all duration-300 md:opacity-60 md:hover:opacity-100;
        text-decoration: none !important;
        &:before {
            @apply absolute left-0 top-1/2 -translate-y-1/2 md:hidden;
            padding-top: 1px;
            content: $icon-arrow-right;
            font-family: $icomoon-font-family;
        }
    }
}

.paginator {
    @apply absolute bottom-2 md:bottom-10 left-1/2 -translate-x-1/2 mix-blend-difference flex;
    div {
        @apply p-3 relative transition-all opacity-30;
        &:after {
            @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white;
            content: '';
            width: 6px;
            height: 6px;
        }
        &.active {
            @apply opacity-100;
        }
    }
}

img, picture {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.input {
    &__pill {
        &, &:focus {
            outline: none;
            cursor: none;
        }
        @apply rounded-full px-5 text-sm transition-all duration-300 text-white/0;
        box-shadow: 0 0 0 10px rgba(0, 87, 253, 0);
        line-height: 1.4rem;
        padding-top: 0.6rem;
        padding-bottom: 0.7rem;
        background: inherit;
        &::placeholder {
            color: #B4B4B4;
            opacity: 0;
            transition: opacity .3s linear;
        }
        &.view {
            @apply text-white;
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7);
            transition: box-shadow .6s $ease-out-cubic;
            &::placeholder {
                opacity: 1;
            }
        }
        &:focus {
            box-shadow: 0 0 0 1px rgba(0, 87, 253, 1);
            transition: all .3s linear;
        }
        &[data-checkbox]:hover {
            box-shadow: 0 0 0 1px rgba(0, 87, 253, 1);
        }
        &.checked {
            box-shadow: 0 0 0 1px rgba(0, 87, 253, 1);
            background: rgb(0, 87, 253);
        }
        &.error {
            box-shadow: 0 0 0 1px rgba(255, 0, 0, 1);
        }
    }
    &__submit {
        @apply px-10;
        &.view:hover {
            box-shadow: 0 0 0 1px rgb(0, 87, 253);
            background: rgb(0, 87, 253);
        }
    }
    &__error {
        @apply text-base text-red-600 transition duration-300 opacity-0;
        &.view {
            @apply opacity-100;
        }
    }
}
.form__part {
    @apply scale-90 opacity-0 pointer-events-none;
    transition: opacity .4s linear, transform .4s $ease-out-cubic;
    &.view {
        @apply scale-100 opacity-100 pointer-events-auto;
        transition: opacity .7s linar .6s, transform 1.0s $ease-out-cubic .6s;
    }
}
.form__success {
    @apply w-full;
    position: absolute;
}

div[data-checkbox] {
    &:hover {
        .input__pill.view {
            box-shadow: 0 0 0 3px rgba(0, 87, 253, 1);
        }
    }
}
