.animate-fade-in {
    animation: fadeIn 1s both;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
    }
}

.animate-fade-out {
    animation: fadeIn 1s both;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
    }
}

.animate-scale-in {
    animation: scaleIn 1s both;
    @keyframes scaleIn {
        from {
            transform: scale(.9);
        }
    }
}

.animate-reveal-down {
    mask-image: linear-gradient(to bottom, #000 33.3%, #0000 66.6%);
    mask-size: 100% 300%;
    animation: fadeDown 3s;
    @keyframes fadeDown {
        from {
            mask-position: left 100%;
        }
        to {
            mask-position: left 0%;
        }
    }
}

.animate-fade-in-up {
    animation: fadeInUp 1s both;
    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(60px);
        }
    }
}

.animate-fade-out-up {
    animation: fadeOutUp 1s both;
    @keyframes fadeOutUp {
        to {
            opacity: 0;
            transform: translateY(-60px);
        }
    }
}

.animate-pulse-with-pause {
    animation: pulseWithPause 10s both;
    @keyframes pulseWithPause {
        0% {
            opacity: 1;
        }
        20% {
            opacity: .2;
        }
        40% {
            opacity: 1;
        }
    }
}

.animate-fade-out {
    animation: fadeOut 1s both;
    @keyframes fadeOut {
        to {
            opacity: 0;
        }
    }
}

.text--fade-in, .text--fade-out,
.text--fade-in-slow, .text--fade-out-slow,
.text--fade-in-top, .text--fade-out-top,
.text--fade-in-bottom, .text--fade-out-bottom,
.text--zoom-in, .text--zoom-out, {
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s linear, transform .3s $ease-out-cubic;

    &.text--visible {
        opacity: 1;
        pointer-events: all;
    }

    &.text--hidden {
        opacity: 0;
        pointer-events: none;
    }
}

.text--fade-in {
    &.text--visible {
        transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
}

.text--fade-out {
    &.text--hidden {
        transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
}

.text--fade-in-slow {
    &.text--visible {
        transition: opacity 2s linear, transform .5s $ease-out-cubic;
    }
}

.text--fade-out-slow {
    &.text--hidden {
        transition: opacity 2s linear, transform .5s $ease-out-cubic;
    }
}

.text--fade-in-top {
    @apply -translate-y-10;
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
    &.text--visible {
        @apply -translate-y-0;
        transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
}

.text--fade-out-top {
    &.text--hidden {
        @apply -translate-y-10;
        transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
}
.text--fade-in-bottom {
    @apply translate-y-10;
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
    &.text--visible {
        @apply translate-y-0;
        transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
}

.text--fade-out-bottom {
    &.text--hidden {
        @apply -translate-y-10;
        transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
}

.text--zoom-in {
    div {
        transform: scale(1.2);
    }

    &.text--visible {
        transition: opacity .5s linear, transform .5s $ease-out-cubic;

        div {
            transform: scale(1);
            transition: opacity .5s linear, transform .5s $ease-out-cubic;
        }

        pointer-events: all;
    }
}

.text--zoom-out {
    &.text--hidden {
        transition: opacity .5s linear, transform .5s $ease-out-cubic;

        div {
            transform: scale(1.2);
            transition: opacity .5s linear, transform .5s $ease-out-cubic;
        }
    ;
    }
}
