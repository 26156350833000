@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?gvxkbz');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?gvxkbz#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?gvxkbz') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?gvxkbz') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?gvxkbz') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?gvxkbz##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-reload-alt {
  &:before {
    content: $icon-reload-alt; 
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge; 
  }
}
.icon-xing {
  &:before {
    content: $icon-xing; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-brand {
  &:before {
    content: $icon-brand; 
  }
}
.icon-social {
  &:before {
    content: $icon-social; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-right1 {
  &:before {
    content: $icon-arrow-right1; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-down1 {
  &:before {
    content: $icon-arrow-down1; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-envelop {
  &:before {
    content: $icon-envelop; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-contact {
  &:before {
    content: $icon-contact; 
  }
}
.icon-letter {
  &:before {
    content: $icon-letter; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-brand1 {
  &:before {
    content: $icon-brand1; 
  }
}
.icon-social1 {
  &:before {
    content: $icon-social1; 
  }
}
.icon-volume-100 {
  &:before {
    content: $icon-volume-100; 
  }
}
.icon-speaker {
  &:before {
    content: $icon-speaker; 
  }
}
.icon-loud {
  &:before {
    content: $icon-loud; 
  }
}
.icon-volume-cross {
  &:before {
    content: $icon-volume-cross; 
  }
}
.icon-speaker1 {
  &:before {
    content: $icon-speaker1; 
  }
}
.icon-mute {
  &:before {
    content: $icon-mute; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}

