.team-scroll {
    $root: &;
    &__trigger {
        width: 20px;
        height: 1px;
    }
    &__image {
        @apply absolute h-screen-50 md:h-auto;
        opacity: 0;
        max-width: 100vw;
        transition: all .3s $ease-out-cubic;
        width: 100%;
        img, picture {
            @apply w-full h-full object-cover md:h-auto md:object-fill;
        }
        &--0, &--2, &--4 {
            top: 0;
        }
        &--1, &--3, &--5 {
            top: 50vh;
        }
        @media (min-width: 768px) {
            &--0 {
                width: 400px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &--1 {
                width: 296px;
                left: 0;
                top: 0;
            }
            &--2 {
                width: 255px;
                right: 0;
                bottom: 0;
            }
            &--3 {
                width: 413px;
                max-width: 45vw;
                top: 50%;
                right: 50%;
                transform: translate(0, -50%);
            }
            &--4 {
                width: 279px;
                top: 0;
                right: 0;
                transform: translate(-100%, 0);
            }
        }

        &.view {
            opacity: 1;
        }
    }
}
