.framed-video-module__container {
    transition: background .5s linear;
}
.framed-video-module .laptop-frame {
    height: 100%;
}

@media (max-aspect-ratio: 5088/3000) { // size of image, 3000px > slightly larger than screen width
    .framed-video-module .laptop-frame {
        width: 100%;
        height: auto;
    }
}


.framed-video-module .tablet-frame {
    height: 100%;
}

@media (max-aspect-ratio: 3548/2712) { // size of image
    .framed-video-module .tablet-frame {
        width: 100%;
        height: auto;
    }
}
@media (max-aspect-ratio: 345/451) {
    .framed-video-module .tablet-frame {
        width: 100%;
        height: auto;
        .prose, p, span {
            font-size: 16px;
        }
    }
}
