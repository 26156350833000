.sticky-split {
    $root: &;
    &__scrollcontent {
        font-weight: 100;
        line-height: 1.3em;
        div {
            opacity: 0;
            transform: translate(0, 60px);
            transition: all 1s $ease-out-quad;
        }
        &.view div {
            opacity: 1;
            transform: translate(0, 0);
            transition: all 1s $ease-out-quad .5s;
        }
        &.out div {
            opacity: 0;
            transform: translate(0, -60px);
            transition: all 1s $ease-out-quad;
        }
        b, strong {
            font-weight: 400;
        }
    }
    &__text {
        overflow: hidden;
    }
    &__scrollcontent__trigger {
        width: 20px;
        min-height: 1px;
        background: #900;
    }
}

@media (max-width: 768px) {
    .sticky-split {
        $root: &;
    }
}
