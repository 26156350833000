.text-gradient {
    &__text {
        line-height: 1.6em;
        padding: 50vh 0;
        font-weight: 400;
    }
    &__gradient {
        @apply absolute left-0 top-0 right-0 h-full pointer-events-none;
        background: linear-gradient(0deg, #fff, #fff, #009, #fff, #fff);
        transform: translate(0, -50%);
        mix-blend-mode: lighten;
        &.dark {
            background: linear-gradient(0deg, #000, #000, #000, #009, #fff, #009, #000, #000, #000);
            mix-blend-mode: darken;
        }
    }
}
@media (max-width: 600px) {
    .text-gradient {
        &__text {
            padding: 33vh 0;
        }
    }
}
