
.prose {
    p {
        margin-bottom: 1.5rem; // equals 24px with default 16px font size
    }

    & > :last-child {
        margin-bottom: 0;
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

    ul, ol {
        padding-left: 1.2rem;
        margin-bottom: 1.5rem;

        // matches default tailwind "sm" breakpoint
        @media (min-width: 640px) {
            padding-left: 2.2rem;
        }

        li:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

.text {
    &-tiny {
        @apply text-xs;
    }
    &-small {
        @apply text-sm;
    }
    &-large {
        font-size: 20px;
        line-height: 28px;
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    &-larger {
        font-size: 24px;
        line-height: 36px;
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    &-big {
        //@apply text-2xl md:text-3xl;
        font-size: 32px;
        line-height: 48px;
        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 36px;
        }
    }
    &-huge {
        //@apply text-2xl md:text-4xl;
        font-size: 40px;
        line-height: 52px;
        @media (max-width: 1200px) {
            font-size: 40px;
            line-height: 52px;
        }
        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 48px;
        }
    }
    &-headline {
        //@apply text-3xl md:text-4xl font-black;
        font-size: 80px;
        line-height: 104px;
        @media (max-width: 1200px) {
            font-size: 68px;
            line-height: 80px;
        }
        @media (max-width: 768px) {
            font-size: 40px;
            line-height: 52px;
        }
    }
}

.pimcore_wysiwyg {
    cursor: default;
    a {
        color: #069;
        text-decoration: underline;
    }
    b, strong {
        font-weight: 600;
    }
}
