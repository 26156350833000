header {
    @apply fixed top-0 left-0 right-0;
    z-index: 800;
    mix-blend-mode: difference;

    .burger {
        @apply absolute;
        left: 40px;
        top: 40px;
        width: 40px;
        height: 40px;
        div {
            @apply bg-white absolute left-1/2 top-1/2;
            width: 17px;
            height: 3px;
            transition: all .3s $ease-out-cubic;
            &:nth-child(1) {
                transform: translate(-50%, -50%);
            }
            &:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        }
        &.open {
            div:nth-child(1) {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            div:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    .pagetitle, .websitetitle {
        @apply absolute left-1/2 pointer-events-none font-bold uppercase;
        top: 60px;
        font-size: 17px;
        opacity: 0;
        transform: translate(-50%, -150%);
        transition: all .5s $ease-out-cubic;
        &.view {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }
    .websitetitle.view {
        @apply pointer-events-auto;
    }

    .social {
        @apply absolute right-0 pointer-events-none;
        top: 60px;
        margin-right: 60px;
        opacity: 0;
        transform: translate(0, -150%);
        transition: all .5s $ease-out-cubic;

        a {
            @apply inline-block p-3 relative transition-colors duration-300 text-white hover:text-black focus:text-black;
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                box-shadow: 0 0 0 1px #fff, inset 0 0 0 0 #fff;
                border-radius: 100%;
                width: 32px;
                height: 32px;
                transition: all .3s $ease-out-cubic;
                z-index: -1;
            }
            &:hover, &:focus {
                &:before {
                    box-shadow: 0 0 0 2px #fff, inset 0 0 0 40px #fff;
                }
            }
        }

        &.view {
            @apply pointer-events-auto;
            opacity: 1;
            transform: translate(0, -50%);
            transition: all .5s $ease-out-cubic .1s;
        }
    }

    &.open {
        .pagetitle, .pagetitle.view {
            opacity: 0;
        }
    }
}

nav {
    @apply fixed left-0 right-0 top-0 overflow-hidden;
    height: 0;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
    transition: height .3s $ease-out-cubic;
    z-index: 799;
    .menu__content {
        @apply absolute left-1/2 -translate-x-1/2;
        top: 60px;
        bottom: 200px;
        font-size: 40px;
        a {
            display: inline-block;
            transition: all .3s ease-out;
            color: rgba(255, 255, 255, 0.5);
            opacity: 0;
            transform: translate(0, -20px);
            &.active {
                color: rgba(255, 255, 255, 1);
            }
            &.active-trail {
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
    a.meta {
        @apply md:hover:text-white text-neutral-300 md:text-xl transition-all duration-300 opacity-0;
    }
    &.open {
        height: 100vh;
        transition: height .3s $ease-out-cubic;
        .menu__content {
            a {
                opacity: 1;
                transform: translate(0, 0);
                @for $i from 1 through 20 {
                    &:nth-child(#{$i}) {
                        transition: color .3s linear, opacity .3s linear .2s + 0.05s * $i, transform .3s $ease-out-cubic .2s + 0.05s * $i;
                    }
                }
            }
        }
        a.meta {
            @apply opacity-100;
            transition: opacity .5s linear 1s;
        }
    }
}
@media (max-width: 767px) {
    header {
        .burger {
            left: 22px;
            top: 22px;
        }
        .social {
            top: 40px;
            margin-right: 10px;
            &, &.view {
                pointer-events: none;
                opacity: 0;
            }
        }
        .pagetitle, .websitetitle {
            @apply text-center left-[80px] right-[80px] -translate-x-0 -translate-y-1.5;
            top: 40px;
            &.view {
                @apply -translate-y-1/2;
            }
        }
        &.open {
            .pagetitle, .websitetitle {
                opacity: 0;
                pointer-events: none;
            }
            .social {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    nav {
        .menu__content {
            font-size: 36px;
        }
    }
}
@media (min-width: 769px) {
    header {
        .burger {
            &:hover, &.open {
                div:nth-child(1) {
                    transform: translate(-50%, 50%);
                }
                div:nth-child(2) {
                    transform: translate(-50%, -150%);
                }
            }
            &.open:hover {
                div:nth-child(1) {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                div:nth-child(2) {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }
    nav {
        .menu__content {
            a {
                cursor: none;
                padding: 10px;
                &:hover {
                    transition: all 0s;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
}
footer {
    font-size: 20px;
    a {
        @apply inline-block relative;
        &:before {
            content: '';
            position: absolute;
            transition: all .3s $ease-out-cubic;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: #fff;
        }
    }
    @media (min-width: 768px) {
        a:hover {
            &:before {
                transition: all .7s $ease-out-cubic;
                width: 100%;
            }
        }
    }
}
@media (max-height: 900px) and (min-width: 768px) {
    nav {
        .menu__content {
            font-size: 32px;
            bottom: 150px;
            a {
                padding: 0;
            }
        }
    }
}
