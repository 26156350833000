.video-timeline__text {
    @apply absolute left-0 right-0;
    &.text--center {
        @apply top-1/2 -translate-y-1/2 px-10;
    }
    &.text--center--left {
        @apply top-1/2 -translate-y-1/2 left-0 right-1/2 pl-10 pr-0 text-right;
        div {
            @apply inline-block origin-right;
        }
    }
    &.text--center--right {
        @apply top-1/2 -translate-y-1/2 left-1/2 right-0;
        div {
            @apply inline-block origin-left;
        }
    }
    &.text--center--above {
        @apply top-1/2 -translate-y-full px-10;
    }
    &.text--center--below {
        @apply top-1/2 px-10;
    }
    &.text--top {
        @apply top-10 p-10;
    }
    &.text--bottom {
        @apply bottom-0 p-10;
    }
    div {
        @apply lg:max-w-60vw;
        margin: 0 auto;
    }
}
