$icomoon-font-family: "icon" !default;
$icomoon-font-path: "fonts" !default;

$icon-reload-alt: "\e908";
$icon-enlarge: "\e909";
$icon-xing: "\e903";
$icon-facebook: "\e904";
$icon-brand: "\e904";
$icon-social: "\e904";
$icon-instagram: "\e900";
$icon-arrow-right: "\e90c";
$icon-arrow-right1: "\e90d";
$icon-arrow-down: "\e90e";
$icon-arrow-down1: "\e90f";
$icon-phone: "\e906";
$icon-envelop: "\e905";
$icon-mail: "\e905";
$icon-email: "\e905";
$icon-contact: "\e905";
$icon-letter: "\e905";
$icon-linkedin: "\e901";
$icon-brand1: "\e901";
$icon-social1: "\e901";
$icon-volume-100: "\e90a";
$icon-speaker: "\e90a";
$icon-loud: "\e90a";
$icon-volume-cross: "\e90b";
$icon-speaker1: "\e90b";
$icon-mute: "\e90b";
$icon-play: "\e907";
$icon-pinterest: "\e902";

