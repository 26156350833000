.tab {
    $root: &;
    position: relative;
    &__bar {
        @apply flex items-center justify-start gap-1 ml-5;
        div {
            @apply flex-initial;
            padding: 5px 10px;
            cursor: pointer;
            background: #f9f9f9;
            color: #666;
            border-radius: 5px 5px 0 0;
            &.active, &:hover {
                background: #ccc;
                color: #000;
            }
        }
    }
    &__content {
        background: #f9f9f9;
        border: 2px solid #ccc;
        border-radius: 2px;
        & > div {
            display: none;
            padding: 10px;
            &.view {
                display: block;
            }
        }
    }
}
main.backendmode {
    .cke_editable {
        color: #000;
    }
}
