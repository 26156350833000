.lottie-split {
    $root: &;
    &__text {
        @apply py-5 overflow-hidden;
        transition: all 1s $ease-out-quad;
        transform: translate(0, 80px);
        //* {
        //    line-height: 3.5em !important;
        //    transition: all 1s $ease-out-quad;
        //}
        &__gradient {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 200%;
            bottom: 0;
            pointer-events: none;
        }
        &--nofade {
            transform: translate(0, 0);
            #{$root}__text__gradient {
                display: none;
            }
        }
    }
    &__videoText {
        transition: all 1s $ease-out-quad;
        //transform: translate(0, 80px);
        opacity: 0;
    }
    &__award {
        @apply text-center opacity-0 duration-1000 transition-all;
        img {
            @apply object-contain w-full h-full object-center;
        }
        @for $i from 1 through 21 {
            &:nth-child(#{$i}) {
                transition-delay: 2000ms + $i * 100;
                img {
                    animation: award 3s + random(20) * .1s linear infinite;
                    animation-delay: random(100) * .01s;
                }
            }
        }
    }
    &__scrollcontent {
        font-weight: 100;
        pointer-events: none;
        opacity: 0;
        transition: all 1s $ease-out-quad;
        &.view {
            pointer-events: all;
            opacity: 1;
            transition: all 1s $ease-out-quad .2s;
            &.nofade {
                transition: all .5s $ease-out-quad;
            }
            #{$root}__text {
                transform: translate(0, 0);
                transition: all 4s $ease-out-quad .6s;
                //* {
                //    line-height: 1.6em !important;
                //    transition: all 3s $ease-out-quad 1s;
                //}
                #{$root}__text__gradient {
                    transform: translate(0, 100%);
                    transition: all 3s ease-in-out .6s;
                }
            }
            #{$root}__videoText {
                transform: translate(0, 0);
                opacity: 1;
                transition: all 4s $ease-out-quad 1.4s;
            }
            #{$root}__award {
                @apply opacity-100;
            }
        }
        &.out {
            opacity: 0;
            transition: all 1s $ease-out-quad;
            pointer-events: none;
        }
        b, strong {
            font-weight: 400;
        }
    }
    &__scrollcontent__trigger {
        width: 1px;
        min-height: 1px;
        //background: rgba(150, 0, 0, 0.3);
    }
}
@keyframes award {
    0% {
        opacity: 1;
    }
    30% {
        opacity: .3;
    }
    50% {
        opacity: .8;
    }
    80% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}
@media (max-width: 768px) {
    .lottie-split {
        $root: &;
        &__award {
            max-height: 10vh !important;
        }
        &__scrollcontent {
            font-size: 25px;
        }
    }
}
