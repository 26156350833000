.scrolltrigger-text {
    $root: &;
    &__content {
        #{$root}__image {
            @apply h-full w-full;
            opacity: 0;
            transition: all 1s $ease-out-quad;
            img {
                @apply object-cover w-full h-full;
            }
            &.view {
                opacity: 1;
            }
            &.out {
                opacity: 0;
            }
        }
        #{$root}__text {
        }
        a {
            text-decoration: underline;
        }
        b, strong {
            font-weight: 400;
        }
        .text--center {
            @apply top-1/2 -translate-y-1/2 px-10;
        }
        .text--center--above {
            @apply top-1/2 -translate-y-full px-10;
        }
        .text--center--below {
            @apply top-1/2 px-10;
        }
        .text--top {
            @apply top-10 p-10;
        }
        .text--bottom {
            @apply bottom-0 p-10;
        }
    }
    &__trigger {
        width: 1px;
        min-height: 1px;
        z-index: +1;
        &[data-scrolltrigger-text='end'] {
            transform: translate(2px, 0);
        }
    }
}

@media (max-width: 768px) {
    .sticky-split {
        $root: &;

        &__scrollcontent {
            font-size: 25px;
        }
    }
}
